@font-face {
    font-family: "Attila Sans Sharp";
    src: url("../../assets/fonts/AttilaSansSharp-Black.woff") format("opentype");
}

.loadingScreen h1 {
    font-family: "Attila Sans Sharp" !important;
    color: azure;
}
.loadingScreen h5 {
    font-family: "Attila Sans Sharp" !important;
    color: azure;
}

.myboard {
    position: fixed;
    top: 126px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 0;
    overflow-x: hidden;
    overflow-y: hidden;
}

.canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)';
}

.mouseTracker {
    display: none;
    position: absolute;
    border: 2px solid white;
}

.nTracker {
    display: none;
    position: absolute;
    border: 2px dashed white;
}

.boxTracker {
    position: absolute;
    border: 4px solid red;
    user-select: none;
}

.clickTracker {
    position: absolute;
    border: 4px dashed white;
    user-select: none;
}

.multiSelection {
    position: absolute;
    display: none;
    border: 2px solid red;
}

.name {
    position: absolute;
    z-index: 1;
    user-select: none;
}

.name h1{
    color: grey;
    font-family: "Attila Sans Sharp" !important;
}

.name h1:hover {
    color: white;
}

.neighborhoodDashboard h1 {
    font-family: "Attila Sans Sharp" !important;
    color: white;
}

.neighborhoodDashboard h5 {
    font-family: "Attila Sans Sharp" !important;
    color: azure;
}

.sidebarDivider {
    font-family: "Attila Sans Sharp" !important;
    font-size: 16px;
    color: whitesmoke;
}



.sensor {
    position: absolute;
    top: 0;
    left: 0;
}


.sidenav {
    position: fixed;
    z-index: 1;
    height: 80%;
    width: 0;
    top: 150px;
    bottom: 24px;
    left: 24px;
    border-radius: 15px;
    background: black;
    overflow-x: hidden;
    transition: 0.5s;
    /* filter: blur(1rem); */
}

.notifications-sidenav{
    height: 100%;
    left: inherit;
    right: 24px;
    top: 126px;
    right: 0;
    background: #000000FF;
    border-radius: 0;
}

.sidenav .position {
    display: block;
    margin: 20px;
    color: white;
}


.sidenav .info .newColor {
    width: 50px;
    /* margin: 10px; */
    border-radius: 5px;
}

.sidenav .info .newColor ,textarea{
    background: black;
}

.sidenav .price {
    width: 120px;
    height: 20px;
    margin-left: 30px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid grey;
}

.sidenav .info .price, textarea{
    color: black;
    background-color: white;
}

.sidenav .infoHeader {
    color: azure;
    font-size: 14px;
    font-family: "Open Sans";
}

.sidenav .infoText1 {
    color: azure;
    font-size: 12px;
    font-family: "Open Sans";
}

.sidenav .infoText2 {
    color: azure;
    font-size: 10px;
    font-family: "Open Sans";
}

.sidenav .close {
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 25px;
    color: grey;
}

.sidenav .close:hover{
    color: white;
}

.botnav {
    position: fixed;
    z-index: 1;
    bottom: 50px;
    right: 50px;
    width: 100px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.topnav {
    position: fixed;
    z-index: 1;
    top: 175px;
    right: 50px;
    width: 150px;
}


.primaryButton {
    width: 100%;
    background: linear-gradient(270deg, rgb(255, 235, 224) -68.71%, rgb(255, 209, 209) 26.57%, rgb(255, 130, 242) 149.08%);
    border-radius: 8px !important;
    margin: 5px 0 !important;
    height: 41px;
}

.primaryButtonText{
    color: black;
    font-family: "DM Sans" !important;
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: -0.01em;
    margin: 0;
    text-transform: none !important;
}

.secondaryButton {
    width: 100%;
    height: 41px;
    background: rgba(255, 117, 212, 0.16) !important;
    border-radius: 8px !important;
    margin: 5px 0 !important;
    border: 1px solid #FFD1D1 !important
}

.secondaryButtonText {
    background-image: linear-gradient(270deg, #FFEBE0 -68.71%, #FFD1D1 26.57%, #FF82F2 149.08%);
    -webkit-text-fill-color: transparent;
    margin: 0;
    font-family: "DM Sans" !important;
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-transform: none !important;
    -webkit-background-clip: text;
}

.dm-24{
    font-family: "DM Sans" !important;
    font-size: 24px;
}

.dm-16 {
    font-family: "DM Sans" !important;
    font-size: 16px;
}

.defaultText{
    font-family: DM Sans !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: gray;
}

.viewDetails {
    display: flex;
    width: 100%;
    height: 41px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    background-size:cover;
    backdrop-filter: blur(4px);
    align-items: center;
    justify-content: center;
}

.viewText {
    position: static;
    width: 94px;
    height: 21px;
    left: 126px;
    top: 10px;

    font-family: DM Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;

    letter-spacing: -0.01em;

    color: #FFFFFF;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
}

.lowercase {
    text-transform: lowercase !important;
}

.mainText {
    width: 141px;
    height: 31px;
    left: 30px;
    top: 366px;

    font-family: DM Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;

    color: white;
}

#search-tooltip{
    margin-left: 10px;
}